import "canvasjs/dist/canvasjs.3"
let chartPie = {};
chartPie.chartJS = (data,configOption) => {
    var chart = new CanvasJS.Chart('chartPie', {
        exportEnabled: true,
        animationEnabled: true,
        title:{
            text:  configOption.title || 'Biểu đồ',
            fontFamily:"arial",
            fontSize:18
        },
        legend:{
            cursor: "pointer",
            // itemclick: explodePie
        },
        toolTip:{
            fontFamily:"Arial"
        },
        data: [{
            indexLabelFontSize:14,
            indexLabelFontColor:"black",
            type: "pie",
            showInLegend: true,
            toolTipContent: "{name}: <strong>{y}%</strong>",
            indexLabel: "{name} - {y}%",
            dataPoints: data
        }]
    });
    chart.render();
    
};

// chartPie.explodePie = (e)=> {
// 	if(typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
// 		e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
// 	} else {
// 		e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
// 	}
// 	e.chart.render();

// }
export default chartPie;