import ApiService from '../service/ApiService';

let moduleApi = {};
let moduleName = "survey";

moduleApi.create = (dataForm) => {
  let api = {
    module: moduleName,
    type: "create",
    value: dataForm,
    message: {
      success: "",
      error: ""
    },
  };
  return ApiService(api);
};

moduleApi.update = (dataForm) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    // message: {
    //   success: "hidden",
    //   error: "hidden"
    // },
    value: dataForm
  };
  return ApiService(api);
};

moduleApi.list = (dataFilter) => {
  let api = {
    module: moduleName,
    type: "list",
    value: dataFilter
  };
  return ApiService(api);
};


moduleApi.delete = (id) => {
  let api = {
    module: moduleName,
    type: "delete",
    value: { id: id }
  };
  return ApiService(api);
};


moduleApi.findById = (id) => {
  let api = {
    module: moduleName,
    type: "findById",
    value: { id: id }
  };
  return ApiService(api);
};


export default moduleApi;
