import ApiService from '../service/ApiService';

let moduleApi = {};
let moduleName = "survey_item";

moduleApi.create = (dataForm) => {
  let api = {
    module: moduleName,
    type: Array.isArray(dataForm) ? "batchCreate" : "create",
    value: dataForm,
    message: {
      success: "",
      error: ""
    },
  };
  return ApiService(api);
};
moduleApi.createManyItem = (dataForm) => {
  let api = {
    module: moduleName,
    type: "createMany",
    value: dataForm,
    message: {
      success: "",
      error: ""
    },
  };
  return ApiService(api);
};

moduleApi.update = (dataForm) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    message: {
      success: "",
      error: ""
    },
    value: dataForm
  };
  return ApiService(api);
};

moduleApi.list = (dataFilter) => {
  let api = {
    module: moduleName,
    type: "list",
    value: dataFilter
  };
  return ApiService(api);
};

moduleApi.delete = (id) => {
  let api = {
    module: moduleName,
    type: "delete",
    value: {id: id}
  };
  return ApiService(api);
};

export default moduleApi;
