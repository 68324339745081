import LanguageService from './LanguageService';

var NProgress = require('nprogress');
NProgress.configure({
  trickleSpeed: 1000,
  minimum: 0.2,
});

function blockUi() {
  NProgress.start();
  $("body").css("pointer-events", "none");
}

function unblockUi() {
  setTimeout(() => {
    NProgress.done();
    Ladda.stopAll();
    $("body").css("pointer-events", "auto");
  }, 200);
}

window.requestCount = 0;

let ApiService = {};

ApiService = (objData) => {

  if (window.requestCount == 0 && !objData.dontLoading) {
    blockUi();
  }
  window.requestCount += 1;

  // objData = {
  //   method: "",
  //   url: "",    
  //   headers: {},
  //   params: {},
  //   data: {},
  //   type: "",
  //   message: {      
  //     success: "",
  //     error: "",
  //   },
  // }

  let config = {
    method: objData.method
  };
  if (objData.params) {
    let str = Object.keys(objData.params).map(function (key) {
      return key + '=' + objData.params[key];
    }).join('&');
    objData.url = objData.url + "?" + str;
  }
  if (objData.data) {
    config.body = JSON.stringify(objData.data);
  }
  if (objData.headers) {
    config.headers = objData.headers;
  } else {
    config.headers = {'Accept': 'application/json', 'Content-Type': 'application/json'};
  }
  return fetch(objData.url, config).then((response) => {
    return response.json();
  })
    .then((response) => {
      checkSuccess(response, objData);
      return response;
    })
    .catch((error) => {
      console.error('Error:', error);
      checkError(error, objData);
      throw error;
    });
};


function checkSuccess(res, objData) {

  window.requestCount -= 1;
  if (window.requestCount == 0) {
    unblockUi();
  }
  if (objData.message && objData.message.success && objData.message.success == "hidden") {
    return;
  }
  if (objData.message && objData.message.success && objData.message.success != "hidden") {
    toastr.success(objData.message.success);
  } else {
    if (objData.type == "create") {
      toastr.success(LanguageService.lang.c_addSuccess);
    } else if (objData.type == "partialUpdate") {
      toastr.success(LanguageService.lang.c_updateSuccess);
    } else if (objData.type == "delete") {
      toastr.success(LanguageService.lang.c_deleteSuccess);
    } else if (objData.type == "batchDelete") {
      if (res.meta && res.meta.successIds && res.meta.successIds.length > 0) {
        toastr.success(LanguageService.lang.c_delete + " " + res.meta.successIds.length + " " + LanguageService.lang.c_recordSuccess);
      }
      if (res.meta && res.meta.failureIds && res.meta.failureIds.length > 0) {
        toastr.error(LanguageService.lang.c_delete + " " + res.meta.failureIds.length + " " + LanguageService.lang.c_recordError);
      }
    }
  }
}

function checkError(err, objData) {

  window.requestCount -= 1;
  if (window.requestCount == 0) {
    unblockUi();
  }
  if (objData.message && objData.message.error && objData.message.error == "hidden") {
    return;
  }
  if (objData.message && objData.message.error && objData.message.error != "hidden") {
    toastr.error(objData.message.error);
  } else {
    if (objData.type == "findById") {
      toastr.error(LanguageService.lang.c_findError);
    } else if (objData.type == "list") {
      toastr.error(LanguageService.lang.c_findError);
    } else if (objData.type == "create") {
      toastr.error(LanguageService.lang.c_addError);
    } else if (objData.type == "partialUpdate") {
      toastr.error(LanguageService.lang.c_updateError);
    } else if (objData.type == "batchDelete") {
      toastr.error(LanguageService.lang.c_deleteError);
    } else if (objData.type == "views") {
      toastr.error(LanguageService.lang.c_findError);
    } else {
      toastr.error(LanguageService.lang.c_findError);
    }

  }
}


export default ApiService;
