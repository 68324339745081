import ApiService from '../service/ApiService';
import ApiServiceFetch from '../service/ApiServiceFetch';

let moduleApi = {};
let moduleName = "survey_result";
moduleApi.list = (dataFilter) => {
  let api = {
    module: moduleName,
    type: "list",
    value: dataFilter
  };
  return ApiService(api);
};
moduleApi.rptSurveyResultText = (dataFilter) => {
  let api = {
    module: "survey_result",
    viewAction: "rptSurveyResultText",
    type: "views",
    value: dataFilter
  };
  return ApiService(api);
};
moduleApi.rptSurveyResult = (dataFilter) => {
  let api = {
    module: "survey_result",
    viewAction: "rptSurveyResult",
    type: "views",
    value: dataFilter
  };
  return ApiService(api);
};
moduleApi.getDataFromUrl = (url, method, headers, body) => {
  let objData = {
    method: method,
    url: url,
    headers: headers,
    body: body,
    message: {
      success: "",
      error: "",
    },
  };
  return ApiServiceFetch(objData)
};

moduleApi.create = (dataForm, params) => {
  let api = {
    module: moduleName,
    type: "create",
    value: dataForm,
    message: {
      success: "hidden",
      error: "Gửi thông tin khai báo không thành công"
    },
    params: params
  };
  return ApiService(api);
};

moduleApi.update = (dataForm, params) => {
  let api = {
    module: moduleName,
    type: "partialUpdate",
    value: dataForm,
    message: {
      success: "hidden",
      error: "Gửi thông tin khai báo không thành công"
    },
    params: params
  };
  return ApiService(api);
};

moduleApi.delete = (id) => {
  let api = {
    module: moduleName,
    type: "delete",
    value: { id: id },
    message: {
      success: "Xóa thông tin khai báo thành công",
      error: "Xóa thông tin khai báo không thành công"
    },
  };
  return ApiService(api);
};

moduleApi.confirm = (body, params) => {
  let api = {
    module: moduleName,
    type: "confirm",
    value: body,
    message: {
      success: "hidden",
      error: "hidden"
    },
    params: params
  };
  return ApiService(api);
};
export default moduleApi;
