import LanguageService from './LanguageService';
import mushroom from './mushroom.api.def';

var NProgress = require('nprogress');
NProgress.configure({
  trickleSpeed: 1000,
  minimum: 0.2,
});

function blockUi() {
  NProgress.start();
  $("body").css("pointer-events", "none");
}

function unblockUi() {
  setTimeout(() => {
    NProgress.done();
    Ladda.stopAll();
    $("body").css("pointer-events", "auto");
  }, 200);
}

window.requestCount = 0;

let ApiService = {};

ApiService = (objData) => {
  if (window.requestCount == 0 && !objData.dontLoading) {
    blockUi();
  }
  window.requestCount += 1;

  if (objData.type && objData.type != "views") {
    return mushroom[objData.module][objData.type + "Async"](objData.value, objData.params)
      .then(response => {
        // console.log("response: %o %o", response, objData);
        checkSuccess(response, objData);
        return response;
      })
      .catch(error => {
        checkError(error, objData);
        throw error;
      });
  }
  else {
    return mushroom[objData.module].views[objData.viewAction + "Async"](objData.value, objData.params)
      .then(response => {
        checkSuccess(response, objData);
        return response;
      })
      .catch(error => {
        checkError(error, objData);
        throw error;
      });
  }

};

function checkSuccess(res, objData) {

  window.requestCount -= 1;
  if (window.requestCount == 0) {
    unblockUi();
  }
  if (objData.message && objData.message.success && objData.message.success == "hidden"){
    return;
  }
  if (objData.message && objData.message.success && objData.message.success != "hidden") {
    toastr.success(objData.message.success);
  } else {
    if (objData.type == "create") {
      toastr.success(LanguageService.lang.c_addSuccess);
    }
    else if (objData.type == "partialUpdate") {
      toastr.success(LanguageService.lang.c_updateSuccess);
    }
    else if (objData.type == "delete") {
      toastr.success(LanguageService.lang.c_deleteSuccess);
    }
    else if (objData.type == "batchDelete") {
      if (res.meta && res.meta.successIds && res.meta.successIds.length > 0) {
        toastr.success(LanguageService.lang.c_delete + " " + res.meta.successIds.length + " " + LanguageService.lang.c_recordSuccess);
      }
      if (res.meta && res.meta.failureIds && res.meta.failureIds.length > 0) {
        toastr.error(LanguageService.lang.c_delete + " " + res.meta.failureIds.length + " " + LanguageService.lang.c_recordError);
      }
    }
  }
}

function checkError(err, objData) {

  window.requestCount -= 1;
  if (window.requestCount == 0) {
    unblockUi();
  }
  if (objData.message && objData.message.error && objData.message.error == "hidden"){
    return;
  }
  if (objData.message && objData.message.error && objData.message.error != "hidden") {
    toastr.error(objData.message.error);
  } else {
    if (objData.type == "findById") {
      toastr.error(LanguageService.lang.c_findError);
    }
    else if (objData.type == "list") {
      toastr.error(LanguageService.lang.c_findError);
    }
    else if (objData.type == "create") {
      toastr.error(LanguageService.lang.c_addError);
    }
    else if (objData.type == "partialUpdate") {
      toastr.error(LanguageService.lang.c_updateError);
    }
    else if (objData.type == "batchDelete") {
      toastr.error(LanguageService.lang.c_deleteError);
    }
    else if (objData.type == "views") {
      toastr.error(LanguageService.lang.c_findError);
    }
    else {
      toastr.error(LanguageService.lang.c_findError);
    }

  }
}


export default ApiService;
